import gql from 'graphql-tag';

const GET_COMMENT = gql`
	query comment($commentId: String!) {
		comment(_id: $commentId) {
			_id
			bodyHTML
			creationDate
			tips
			edited
			editDate
			deleted
			user {
				_id
				username
				memberOf {
					_id
					name
					builtin
				}
			}
			children {
				_id
				tips
				creationDate
				account {
					_id
					balance
				}
			}
			post {
				_id
			}
			sub {
				_id
				name
				actionCost
			}
		}
		sessionUser {
			_id
			username
			savedItems {
				comments {
					_id
				}
			}
		}
	}
`;

export default GET_COMMENT;
