import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '/client/app/styles/controls.scss';

import './imageCarousel.scss';
import Hideable from '/client/app/components/common/hideable';

interface IProps {
	urls: string[];
	captions?: string[];
}

export default function ImageCarousel(props: IProps) {
	const { urls, captions } = props;

	const [displayedIndex, setDisplayedIndex] = useState(0);

	function figure(url: string, index: number) {
		const caption = captions && captions.length > index ? captions[index] : null;
		return (
			<figure key={`figure.${url}.${index}`} hidden={displayedIndex !== index}>
				{/* eslint-disable-next-line react/jsx-no-target-blank */}
				<a target="_blank" href={url}>
					<img data-sizes="auto" className="carouselImage" src={url} alt={caption || `image ${index + 1}`} />
				</a>
				<Hideable hidden={urls.length === 1}>
					<div className="carouselIndex">
						{index + 1} of {urls.length}
					</div>
					<div className="carouselNavigation">
						<button
							disabled={displayedIndex === 0}
							type="button"
							className="standardButton navButton"
							onClick={showPreviousImage}
						>
							&lt;
						</button>
						<div />
						<button
							disabled={displayedIndex === urls.length - 1}
							type="button"
							className="standardButton navButton"
							onClick={showNextImage}
						>
							&gt;
						</button>
					</div>
				</Hideable>
				<figcaption hidden={!caption}>{caption}</figcaption>
			</figure>
		);
	}

	function showPreviousImage() {
		setDisplayedIndex(Math.max(0, displayedIndex - 1));
	}

	function showNextImage() {
		setDisplayedIndex(Math.min(urls.length - 1, displayedIndex + 1));
	}

	return (
		<div className="imageCarousel">
			<div className="images">{urls.map((url, index) => figure(url, index))}</div>
		</div>
	);
}

ImageCarousel.propTypes = {
	urls: PropTypes.arrayOf(PropTypes.string).isRequired,
	captions: PropTypes.arrayOf(PropTypes.string),
};

ImageCarousel.defaultProps = {
	captions: undefined,
};
