import React, { ReactElement, useState } from 'react';
import PropTypes from 'prop-types';

import './tagPicker.scss';
import Tag from '/client/app/components/tag/tag';
import Hideable from '/client/app/components/common/hideable';
import TagPalette from '/client/app/components/tagPicker/tagPalette';

interface IInitialState {
	tagIds: string[];
	subTagIds: string[];
}

export interface ISubTag {
	name: string;
	_id: string;
}

export interface ITag {
	_id: string;
	name: string;
	builtin: boolean;
	subTags: ISubTag[];
	community: {
		_id: string;
	};
	post: {
		_id: string;
	};
}

interface IProps {
	tags: ITag[];
	onChange: (tagIds: string[], subTagIds: string[]) => void;
	initialState: IInitialState;
}

export default function TagPicker(props: IProps) {
	const { tags, onChange, initialState } = props;

	const [tagIds, setTagIds] = useState(initialState.tagIds);
	const [subTagIds, setSubTagIds] = useState(initialState.subTagIds);
	const [paletteOpen, setPaletteOpen] = useState(false);

	const subTagParents: Record<string, string> = {};

	tags.forEach(tag => {
		tag.subTags.forEach(subTag => {
			subTagParents[subTag._id] = tag._id;
		});
	});

	function typeForTag(tag: ITag) {
		if (tag.community?._id) return 'community';
		if (tag.post?._id) return 'post';
		return 'site';
	}

	function renderCurrentTags() {
		const tagElements: ReactElement[] = [];
		tagIds.forEach(tagId => {
			const tag = tags.find(t => t._id === tagId);
			const subTagIdsForTag = subTagIds.filter(id => subTagParents[id] === tagId);
			if (!tag) throw new Error(`unable to locate tag ${tagId}`);
			if (subTagIdsForTag.length > 0) {
				subTagIdsForTag.forEach(subTagId => {
					const subTag = tag.subTags.find(st => st._id === subTagId);
					if (!subTag) throw new Error(`unable to locate subtag ${subTagId}`);
					tagElements.push(
						<Tag
							key={tagId}
							tagId={tagId}
							type={typeForTag(tag)}
							name={tag.name}
							subTagId={subTagId}
							subTagName={subTag.name}
							editable
							onRemove={() => removeTag(tagId, subTagId)}
						/>
					);
				});
			} else
				tagElements.push(
					<Tag key={tagId} tagId={tagId} type={typeForTag(tag)} name={tag.name} editable onRemove={() => removeTag(tagId)} />
				);
		});
		return tagElements;
		// return <div>{tagElements}</div>;
	}

	function renderAddTagButton() {
		return (
			<button
				type="button"
				disabled={paletteOpen}
				onClick={() => setPaletteOpen(true)}
				className="standardButton addTagButton"
			>
				add tag
			</button>
		);
	}

	function removeTag(tagId: string, subTagId?: string) {
		let changed = false;

		let newTagIds = tagIds;
		let newSubTagIds = subTagIds;

		// Remove the subtag, if it exists
		if (subTagId && tagIds.includes(tagId)) {
			newSubTagIds = subTagIds.filter(id => id !== subTagId);
			setSubTagIds(subTagIds);
			changed = true;
		}

		// If the subtag existed and was the last for its parent, remove the parent
		if (!subTagId || !newSubTagIds.map(id => subTagParents[id]).includes(tagId)) {
			if (tagIds.includes(tagId)) {
				newTagIds = tagIds.filter(id => id !== tagId);
				setTagIds(newTagIds);
				changed = true;
			}
		}

		if (changed) {
			onChange(newTagIds, newSubTagIds);
		}
	}

	function addTag(tagId: string, subTagId?: string) {
		let changed = false;

		let newTagIds = tagIds;
		let newSubTagIds = subTagIds;

		if (!tagIds.includes(tagId)) {
			newTagIds = [...tagIds, tagId];
			setTagIds(newTagIds);
			changed = true;
		}

		if (subTagId && !subTagIds.includes(subTagId)) {
			newSubTagIds = [...subTagIds, subTagId];
			setSubTagIds(newSubTagIds);
			changed = true;
		}

		if (changed) {
			onChange(newTagIds, newSubTagIds);
		}
		setPaletteOpen(false);
	}

	function renderPalette() {
		return (
			<Hideable hidden={!paletteOpen}>
				<TagPalette tags={tags} onSelect={addTag} onCancel={() => setPaletteOpen(false)} />
			</Hideable>
		);
	}

	return (
		<div className="tagPicker">
			{renderAddTagButton()}
			{renderPalette()}
			{renderCurrentTags()}
		</div>
	);
}

TagPicker.propTypes = {
	tags: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			name: PropTypes.string,
			builtin: PropTypes.bool,
			subTags: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string,
					_id: PropTypes.string,
				})
			),
		})
	),
	onChange: PropTypes.func,
	initialState: PropTypes.shape({
		tagIds: PropTypes.arrayOf(PropTypes.string),
		subTagIds: PropTypes.arrayOf(PropTypes.string),
	}),
};

TagPicker.defaultProps = {
	tags: [],
	onChange: undefined,
	initialState: { tagIds: [], subTagIds: [] },
};
