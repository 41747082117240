import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	href: string;
	children: any;
}

/**
 * Simple link wrapper that renders as a react-router link for relative links and as a standard anchor for external/absolute links.
 *
 * @param props
 * @constructor
 */
export default function Link(props: IProps) {
	const { href, children, ...otherProps } = props;
	if (/^.+:\/\//.test(href))
		return (
			<a className="realLink" href={href} {...otherProps}>
				{children}
			</a>
		);
	return (
		<ReactRouterLink to={href} {...otherProps}>
			{children}
		</ReactRouterLink>
	);
}
