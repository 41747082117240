import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '/client/app/components/link';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getTimeDelta from '../../../util/dateUtils';
import formatCurrency from '../../../util/formatCurrency';
import ArmableButton from '/client/app/components/common/armableButton/armableButton';
import CommunityRuleViolationReportFormContainer from '/client/app/components/communityRuleViolationReportForm/communityRuleViolationReportFormContainer';
import Hideable from '/client/app/components/common/hideable';
import ImageCarousel from '/client/app/components/imageCarousel/imageCarousel';
import UserLink from '/client/app/components/userLink/userLink';
import IFramelyEmbed from '/client/app/components/IFramelyEmbed/IFramelyEmbed';

require('./cardFeedItem.scss');
require('lazysizes');

export default function CardFeedItem(props) {
	const { postData, userActions, queryFullPostData, hideCommentsLink, hideTitle, compact } = props;
	const {
		post: {
			_id,
			url,
			textHTML,
			title,
			user: { username, memberOf },
			sub: { name: sub, actionCost, _id: communityId },
			creationDate,
			thumbnail: { path: thumbnail },
			media,
			embed,
			tips,
			commentCount,
		},
	} = postData;

	const embedThumbnail = embed?.thumbnailURL;
	const embedHTML = compact ? embed?.compactHTML : embed?.html;

	const [reporting, setReporting] = useState(false);

	let isLoggedIn = false;
	let savedPosts;
	let isSavedPost = false;

	if (postData.sessionUser) {
		savedPosts = props.postData.sessionUser.savedItems.posts;
		isSavedPost = savedPosts.find((post) => post._id === _id);
		isLoggedIn = true;
	}
	const { tipPost, savePost, unsavePost } = userActions;
	let preview;

	if (embedHTML) preview = buildHTMLEmbed();
	else if (!textHTML) preview = buildThumbPreview();
	else preview = buildBodyPreview();

	const timeDelta = getTimeDelta(new Date(creationDate));
	let truncatedUsername = username;
	if (truncatedUsername.length > 26) {
		truncatedUsername = `${truncatedUsername.substring(0, 23)}...`;
	}

	function handleSavePost() {
		if (isSavedPost) {
			unsavePost({ variables: { postId: _id } });
		} else savePost({ variables: { postId: _id } });
		isSavedPost = !isSavedPost;
	}
	function handleTipPost() {
		tipPost({ variables: { postId: _id } });
	}

	function buildBodyPreview() {
		// this should be safe in this context as we generate this HTML ourselves
		return <div className="cardFeedItemBodyPreview" dangerouslySetInnerHTML={{ __html: textHTML }} />;
	}

	function getLinkTarget() {
		if (url) return url;
		if (media && media.length > 0) return media[0].path;
		return `/s/${sub}/comments/${_id}`;
	}

	function buildHTMLEmbed() {
		return <IFramelyEmbed embedHTML={embedHTML} />;
	}

	function buildThumbPreview() {
		const thumb = embedThumbnail || thumbnail;
		const urls = !media || !media.length ? [thumb] : media.map((m) => m.path);

		return (
			<div className="cardFeedItemThumbPreview">
				<ImageCarousel urls={urls} />
			</div>
		);
	}
	return (
		<div className="cardFeedItem">
			<div className="cardFeedItemTitleBar">
				<Hideable hidden={hideTitle}>
					<span className="titleContainer">
						<Link target="_blank" href={getLinkTarget()}>
							<div className="cardFeedItemTitle">{title}</div>
						</Link>
					</span>
				</Hideable>
				<div className="cardFeedItemDetails">
					<div className="cardFeedItemDetailsRow1">
						<div className="cardFeedItemUser">
							<UserLink
								username={username}
								isAdmin={memberOf.find((group) => group.name === 'Site Admins' && group.builtin)}
							/>
							&nbsp;
							<FontAwesomeIcon icon={faArrowRight} size="xs" />
							&nbsp;
							<Link href={`/s/${sub}`}>
								<span className="cardFeedItemSub">{sub}</span>
							</Link>
						</div>
						<span className="cardFeedItemTimestamp">{timeDelta}</span>
					</div>
				</div>
			</div>
			<div className="cardFeedItemPreviewContainer">{preview}</div>
			<div className="cardFeedItemTools">
				<span className="cardFeedItemValue">{formatCurrency(tips, true)}</span>
				<div className="cardFeedItemRightTools">
					<ArmableButton tiny onConfirm={handleTipPost} disabled={!isLoggedIn}>
						tip {formatCurrency(actionCost)}
					</ArmableButton>
					<Hideable hidden={hideCommentsLink}>
						<span className="commentValue">
							<Link onMouseOver={queryFullPostData} onFocus={queryFullPostData} href={`/s/${sub}/comments/${_id}`}>
								{commentCount} comments
							</Link>
						</span>
					</Hideable>
					<button type="button" className="textButton" onClick={handleSavePost} disabled={!isLoggedIn}>
						{isSavedPost ? 'unsave' : 'save'}
					</button>
					<button type="button" className="textButton" onClick={() => setReporting(!reporting)} disabled={!isLoggedIn}>
						{reporting ? 'cancel' : 'report'}
					</button>
				</div>
			</div>
			<Hideable hidden={!reporting}>
				<CommunityRuleViolationReportFormContainer
					onSaveOrCancel={() => setReporting(false)}
					communityId={communityId}
					objectId={_id}
					objectType="Post"
				/>
			</Hideable>
		</div>
	);
}

CardFeedItem.propTypes = {
	postData: PropTypes.shape({
		post: PropTypes.shape({
			_id: PropTypes.string.isRequired,
			url: PropTypes.string,
			text: PropTypes.string,
			textHTML: PropTypes.string,
			title: PropTypes.string.isRequired,
			user: PropTypes.shape({
				username: PropTypes.string.isRequired,
				memberOf: PropTypes.arrayOf(
					PropTypes.shape({
						_id: PropTypes.string,
						name: PropTypes.string,
						builtin: PropTypes.bool,
					})
				),
			}).isRequired,
			sub: PropTypes.shape({
				name: PropTypes.string.isRequired,
				actionCost: PropTypes.number.isRequired,
				_id: PropTypes.string.isRequired,
			}).isRequired,
			creationDate: PropTypes.string.isRequired,
			thumbnail: PropTypes.shape({
				path: PropTypes.string,
			}),
			media: PropTypes.arrayOf(
				PropTypes.shape({
					path: PropTypes.string,
				})
			),
			embed: PropTypes.shape({
				html: PropTypes.string,
				compactHTML: PropTypes.string,
				thumbnaiURL: PropTypes.string,
			}),
			// mediaThumbnails: PropTypes.arrayOf(PropTypes.shape({
			// 	path: PropTypes.string
			// })),
			tips: PropTypes.number.isRequired,
			commentCount: PropTypes.number.isRequired,
		}),
		sessionUser: PropTypes.shape({
			savedItems: PropTypes.shape({
				posts: PropTypes.arrayOf(
					PropTypes.shape({
						_id: PropTypes.string,
					})
				),
			}),
		}).isRequired,
	}).isRequired,
	userActions: PropTypes.shape({
		tipPost: PropTypes.func.isRequired,
		savePost: PropTypes.func.isRequired,
		unsavePost: PropTypes.func.isRequired,
	}).isRequired,
	queryFullPostData: PropTypes.func,
	hideCommentsLink: PropTypes.bool,
	hideTitle: PropTypes.bool,
	compact: PropTypes.bool,
};

CardFeedItem.defaultProps = {
	queryFullPostData: undefined,
	hideCommentsLink: false,
	hideTitle: false,
	compact: false,
};
