import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import LoadingWrapper from '../loading/loadingWrapper';
// eslint-disable-next-line import/no-cycle
import Comment from './comment';
import { SAVE_COMMENT, DELETE_COMMENT, TIP_COMMENT, UNSAVE_COMMENT } from '../../graphql/userActions';
import GET_COMMENT from '/client/app/components/comment/commentQuery';
import useCachedQuery from '/client/app/graphql/useCachedQuery';

export default function CommentContainer(props) {
	const { commentId, ssr, sortOrder } = props;
	const { data, loading } = useCachedQuery(GET_COMMENT, { variables: { commentId }, ssr });
	const [tipComment] = useMutation(TIP_COMMENT);
	const [deleteComment] = useMutation(DELETE_COMMENT);
	const [saveComment, { loading: saving }] = useMutation(SAVE_COMMENT);
	const [unsaveComment, { loading: unsaving }] = useMutation(UNSAVE_COMMENT);

	const userActions = {
		tipComment,
		saveComment,
		unsaveComment,
		deleteComment,
	};
	return (
		<LoadingWrapper width="100%" height="3em" loading={loading}>
			{() => <Comment data={data || {}} sortOrder={sortOrder} userActions={userActions} ssr={ssr} modifyingSaveState={saving || unsaving} />}
		</LoadingWrapper>
	);
}

CommentContainer.propTypes = {
	commentId: PropTypes.string.isRequired,
	ssr: PropTypes.bool,
	sortOrder: PropTypes.string,
};

CommentContainer.defaultProps = {
	ssr: true,
	sortOrder: 'top',
};
