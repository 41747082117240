import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const TAGS_IN_SCOPE = gql`
	query tagsInScope($communityId: String, $postId: String) {
		tagsInScope(communityId: $communityId, postId: $postId) {
			_id
			name
			builtin
			post {
				_id
			}
			community {
				_id
			}
			subTags {
				_id
				name
			}
		}
	}
`;
