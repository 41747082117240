import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ITag } from '/client/app/components/tagPicker/tagPicker';
import Hideable from '/client/app/components/common/hideable';
import Tag, { TagType } from '/client/app/components/tag/tag';

import './tagPalette.scss';

interface IProps {
	tags: ITag[];
	onSelect: (tagId: string, subTagId?: string) => void;
	onCancel: () => void;
}

export default function TagPalette(props: IProps) {
	const { tags, onSelect, onCancel } = props;

	const siteTags = tags.filter(tag => !tag.community?._id && !tag.post?._id);
	const communityTags = tags.filter(tag => !!tag.community?._id);
	const postTags = tags.filter(tag => !!tag.post?._id);

	const ref = useRef<HTMLDivElement>(null);

	const [closing, setClosing] = useState(false);

	function shouldClose(event: MouseEvent) {
		if (!event.target || !ref.current?.contains(event.target as Node)) {
			if(!closing) {
				setClosing(true);
				setTimeout(onCancel, 250);
			}
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', shouldClose);

		return () => {
			document.removeEventListener('mousedown', shouldClose);
		};
	});

	function selectTag(tagId:string) {
		if(!closing) {
			setClosing(true);
			setTimeout(() => {
				onSelect(tagId);
			}, 250);
		}
	}

	function tagSection(title: string, sectionTags: ITag[], type: TagType) {
		return (
			<div className="tagPaletteSection" key={`tagPaletteSection.${type}`}>
				<span className="tagPaletteSectionTitle">{title}</span>
				<div className="tagSectionTags">
					{sectionTags.map(tag => (
						<Tag key={tag._id} onClick={() => selectTag(tag._id)} name={tag.name} tagId={tag._id} type={type} />
					))}
				</div>
			</div>
		);
	}

	const classes = ["tagPalette"];

	if(closing)
		classes.push("closing");

	return (
		<div ref={ref} className={classes.join(' ')}>
			<Hideable hidden={siteTags.length === 0}>{tagSection('site tags', siteTags, 'site')}</Hideable>
			<Hideable hidden={communityTags.length === 0}>{tagSection('community tags', communityTags, 'community')}</Hideable>
			<Hideable hidden={postTags.length === 0}>{tagSection('post tags', postTags, 'post')}</Hideable>
		</div>
	);
}

TagPalette.propTypes = {
	tags: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			name: PropTypes.string,
			builtin: PropTypes.bool,
			subTags: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string,
					_id: PropTypes.string,
				})
			),
		})
	).isRequired,
	onSelect: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};
