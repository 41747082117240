import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { COMMUNITY_RULES } from '/client/app/graphql/queries/communityRules';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import CommunityRuleViolationReportForm from '/client/app/components/communityRuleViolationReportForm/communityRuleViolationReportForm';
import { CREATE_COMMUNITY_RULE_VIOLATION_REPORT } from '/client/app/graphql/mutations/communityRuleViolationReports';

interface IProps {
	communityId: string;
	onSaveOrCancel?: () => Promise<void> | void;
	objectType: string;
	objectId: string;
}

export default function CommunityRuleViolationReportFormContainer(props: IProps) {
	const { communityId, onSaveOrCancel, objectType, objectId } = props;

	const { data: communityRulesData, loading: communityRulesLoading } = useQuery(COMMUNITY_RULES, {
		variables: {
			communityId,
		},
	});

	const { data: siteWideData, loading: siteWideLoading } = useQuery(COMMUNITY_RULES);
	const [createCommmunityRuleViolationReportMutation] = useMutation(CREATE_COMMUNITY_RULE_VIOLATION_REPORT);

	const communityRules = communityRulesData?.communityRules || [];
	const siteWideRules = siteWideData?.communityRules || [];
	const loading = communityRulesLoading || siteWideLoading;

	async function saveReport(communityRuleId: string, detailedViolationReport: string, detailedViolationReportHTML: string) {
		await createCommmunityRuleViolationReportMutation({
			variables: {
				communityRuleId,
				objectType,
				objectId,
				detailedViolationReport,
				detailedViolationReportHTML,
			},
		});
	}

	async function cancel() {
		if (onSaveOrCancel) await onSaveOrCancel();
	}

	return (
		<LoadingWrapper width="100%" height="1em" loading={loading}>
			{() => (
				<CommunityRuleViolationReportForm
					onSave={saveReport}
					onCancel={cancel}
					communityId={communityId}
					communityRules={communityRules}
					siteWideRules={siteWideRules}
				/>
			)}
		</LoadingWrapper>
	);
}

CommunityRuleViolationReportFormContainer.propTypes = {
	communityId: PropTypes.string,
	onSaveOrCancel: PropTypes.func,
	objectType: PropTypes.string.isRequired,
	objectId: PropTypes.string.isRequired,
};

CommunityRuleViolationReportFormContainer.defaultProps = {
	onSaveOrCancel: undefined,
	communityId: undefined,
};
