import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import CommentContainer from '/client/app/components/comment/commentContainer';

import '/client/app/styles/error.scss';

interface IComment {
	tips: number;
	creationDate: string | Date;
	_id: string;
}

export const GET_COMMENTS = gql`
	query post($postId: String!) {
		post(_id: $postId) {
			_id
			comments {
				_id
				creationDate
				tips
				account {
					_id
					balance
				}
			}
		}
		sessionUser {
			_id
			savedItems {
				posts {
					_id
				}
				comments {
					_id
				}
			}
		}
		commentsForPost(postId: $postId) {
			_id
			bodyHTML
			creationDate
			tips
			edited
			editDate
			deleted
			user {
				_id
				username
			}
			children {
				_id
				tips
				account {
					_id
					balance
				}
			}
			post {
				_id
				url
				urlTitle
			}
			sub {
				_id
				actionCost
			}
		}
	}
`;

interface IProps {
	postId: string;
	sortOrder: string;
}

export default function PostComments(props: IProps) {
	const { postId, sortOrder } = props;
	const { data, loading, error } = useQuery(GET_COMMENTS, { variables: { postId } });

	function renderError() {
		return <div className="errorLabelMedium">{error?.message}</div>;
	}
	
	function renderComments() {
		return data.post.comments
			.sort((a: IComment, b: IComment) => {
				let diff;
				if(sortOrder === 'new' )
					diff = new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
				else diff = b.tips - a.tips;
				if(diff === 0)
					return a._id.localeCompare(b._id) * -1;
				return diff;
			})
			.map((comment: IComment) => <CommentContainer commentId={comment._id} key={comment._id} />)
	}

	return (
		<LoadingWrapper width="100%" count={5} height="3em" loading={loading}>
			{() => error ? renderError() : renderComments() }
		</LoadingWrapper>
	);
}
