import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

interface IProps {
	embedHTML: string;
}

interface IWindowThatMightHaveIFramely {
	iframely?: {
		load: () => any;
	};
}

export default function IFramelyEmbed(props: IProps) {
	const { embedHTML } = props;

	useEffect(() => {
		const { iframely } = window as IWindowThatMightHaveIFramely;
		if (iframely) iframely.load();
	}, []);

	return <div className="iframelyEmbed" dangerouslySetInnerHTML={{ __html: embedHTML }} />;
}

IFramelyEmbed.propTypes = {
	embedHTML: PropTypes.string.isRequired,
};
