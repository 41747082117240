import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_COMMENT, EDIT_COMMENT } from '/client/app/graphql/userActions';
import CommentEditor from '/client/app/components/commentEditor/commentEditor';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';

const COMMENT_BY_ID = gql`
	query comment($_id: String!) {
		comment(_id: $_id) {
			_id
			bodyHTML
		}
	}
`;

const SESSION_USER = gql`
	query sessionUser {
		sessionUser {
			_id
		}
	}
`;

interface IProps {
	postId: string;
	communityId: string;
	actionCost: number;
	parentCommentId?: string;
	commentId?: string;
	cancel?: () => any;
	onSubmit?: () => any;
	className?: string;
}

export default function CommentEditorContainer(props: IProps) {
	const { postId, communityId, parentCommentId, onSubmit, actionCost, commentId, cancel, className } = props;
	const [createComment] = useMutation(CREATE_COMMENT);
	const [editComment] = useMutation(EDIT_COMMENT);

	const { data, loading } = useQuery(COMMENT_BY_ID, {
		variables: { _id: commentId || '' },
	});

	const { data: sessionData } = useQuery(SESSION_USER);

	async function doCreateComment(body: string, bodyHTML: string) {
		await createComment({ variables: { postId, parentCommentId, body, bodyHTML } });
		if (onSubmit) {
			await onSubmit();
		}
	}

	async function doEditComment(body: string, bodyHTML: string) {
		await editComment({ variables: { commentId, body, bodyHTML } });
		if (onSubmit) {
			await onSubmit();
		}
	}

	return (
		<LoadingWrapper width="100%" height="3em" loading={!!commentId && loading}>
			{() => (
				<CommentEditor
					actionCost={actionCost}
					postId={postId}
					communityId={communityId}
					createCommentFn={doCreateComment}
					editCommentFn={doEditComment}
					loggedIn={sessionData && sessionData.sessionUser}
					cancel={cancel}
					existingHTML={data && data.comment ? data.comment.bodyHTML : undefined}
					className={className}
				/>
			)}
		</LoadingWrapper>
	);
}
