import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { TAGS_IN_SCOPE } from '/client/app/graphql/queries/tags';
import TagPicker from '/client/app/components/tagPicker/tagPicker';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';

interface IProps {
	communityId: string;
	postId: string;
	onChange: (tagIds: string[], subTagIds: string[]) => void;
}

export default function TagPickerContainer(props: IProps) {
	const { communityId, postId, onChange } = props;

	const { data, loading, error } = useQuery(TAGS_IN_SCOPE, { variables: { postId, communityId } });

	return (
		<LoadingWrapper loading={loading} error={error}>
			{() => <TagPicker tags={data?.tagsInScope} onChange={onChange} />}
		</LoadingWrapper>
	);
}

TagPickerContainer.propTypes = {
	communityId: PropTypes.string.isRequired,
	postId: PropTypes.string.isRequired,
	onChange: PropTypes.func,
};

TagPickerContainer.defaultProps = {
	onChange: undefined,
};
