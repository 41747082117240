import React, { useState } from 'react';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';
import htmlSerializer from '/client/app/components/common/inputs/slateEditor/htmlSerializer';
import SlateEditor, { defaultValue } from '/client/app/components/common/inputs/slateEditor/slateEditor';
import Hideable from '/client/app/components/common/hideable';
import ArmableButton from '/client/app/components/common/armableButton/armableButton';
import formatCurrency from '/client/app/util/formatCurrency';

import './commentEditor.scss';
import TagPickerContainer from '/client/app/components/tagPicker/tagPickerContainer';

interface IProps {
	createCommentFn: (body: string, bodyHTML: string) => Promise<void>;
	editCommentFn: (body: string, bodyHTML: string) => Promise<void>;
	actionCost: number;
	existingHTML?: string;
	cancel?: () => any;
	loggedIn: boolean;
	className?: string;
	postId: string;
	communityId: string;
}

export default function CommentEditor(props: IProps) {
	const { createCommentFn, editCommentFn, actionCost, existingHTML, loggedIn, cancel, className, postId, communityId } = props;

	let initialValue;
	if (existingHTML) initialValue = htmlSerializer.deserialize(existingHTML);

	const [submitting, setSubmitting] = useState(false);
	const [bodyValue, setBodyValue] = useState(initialValue || defaultValue);
	const [error, setError] = useState(null);
	const [key, setKey] = useState(0);

	const isEditing = !!existingHTML;

	async function doSubmit() {
		setSubmitting(true);
		try {
			if (isEditing) await editCommentFn(Plain.serialize(bodyValue), htmlSerializer.serialize(bodyValue));
			else await createCommentFn(Plain.serialize(bodyValue), htmlSerializer.serialize(bodyValue));
			setBodyValue(defaultValue);
			setKey(key + 1);
			setError(null);
		} catch (err) {
			setError(err.message);
		}
		setSubmitting(false);
	}

	function isValidComment() {
		return Plain.serialize(bodyValue).trim().length > 0;
	}

	function getSubmitButtonText() {
		if (isEditing) return 'save changes';
		return [
			'save reply',
			<span key="submitButton" className="actionCost">
				{formatCurrency(2 * actionCost)}
			</span>,
		];
	}

	return (
		<div className={className ? `commentEditor ${className}` : 'commentEditor'}>
			<SlateEditor
				key={key}
				className="postReplyBody"
				initialValue={initialValue}
				onChange={(valueJS: any, value: Value) => setBodyValue(value)}
			/>
			<div className="moderationRefundInfo">
				<Hideable hidden={isEditing}>{formatCurrency(actionCost)} refund if no moderation is required for 24 hours</Hideable>
			</div>
			<div className="buttons">
				<ArmableButton
					onConfirm={doSubmit}
					disabled={!isValidComment() || submitting || !loggedIn}
					className="postReplyButton"
				>
					{getSubmitButtonText()}
				</ArmableButton>
				<Hideable hidden={!cancel}>
					<button type="button" onClick={cancel} className="standardButton postReplyButton">
						cancel
					</button>
				</Hideable>
				<TagPickerContainer postId={postId} communityId={communityId} onChange={(tagIds:string[], subTagIds:string[]) => console.log(`New tags: \n${tagIds}\nNew sub-tags: \n${subTagIds}`)} />
			</div>
			<Hideable hidden={!error}>
				<div className="error">{error}</div>
			</Hideable>
		</div>
	);
}
