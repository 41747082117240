import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '/client/app/styles/controls.scss';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SlateEditor from '../common/inputs/slateEditor/slateEditor';
import htmlSerializer from '../common/inputs/slateEditor/htmlSerializer';
// eslint-disable-next-line import/no-cycle
import CommentContainer from './commentContainer';
import getTimeDelta from '../../util/dateUtils';
import ArmableButton from '../common/armableButton/armableButton';
import CommentEditorContainer from '/client/app/components/commentEditor/commentEditorContainer';
import Hideable from '../common/hideable';
import formatCurrency from '../../util/formatCurrency';
import CommunityRuleViolationReportFormContainer from '/client/app/components/communityRuleViolationReportForm/communityRuleViolationReportFormContainer';
import UserLink from '/client/app/components/userLink/userLink';

require('./comment.scss');

export default function Comment(props) {
	const { data, userActions, ssr } = props;
	const {
		comment: {
			_id,
			bodyHTML,
			creationDate,
			edited,
			editDate,
			tips,
			post: { _id: postId },
			user: { username, memberOf },
			sub: { actionCost, name, _id: communityId },
			children,
			deleted,
		},
		sessionUser,
	} = data;
	const { tipComment, deleteComment, saveComment, unsaveComment } = userActions;
	const { modifyingSaveState, sortOrder } = props;
	const savedItems = sessionUser ? sessionUser.savedItems || { comments: [] } : { comments: [] };
	const sessionUsername = sessionUser ? sessionUser.username : undefined;
	const savedComments = savedItems.comments;

	const timeDelta = getTimeDelta(new Date(creationDate));

	let editedTimeDelta;
	if (edited) editedTimeDelta = getTimeDelta(new Date(editDate));

	const [isCollapsed, setCollapsed] = useState(false);
	const [isReplying, setIsReplying] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [reporting, setReporting] = useState(false);

	let isSavedComment = savedComments.find(post => post._id === _id);
	function handleSaveComment() {
		if (isSavedComment) {
			unsaveComment({ variables: { commentId: _id } });
		} else saveComment({ variables: { commentId: _id } });
		isSavedComment = !isSavedComment;
	}

	function onKeyPress(e) {
		if (e.charCode === 13 || e.charCode === 32) {
			setCollapsed(!isCollapsed);
			e.preventDefault();
		}
	}

	function toggleReply(e) {
		setIsReplying(!isReplying);
		if (e) e.preventDefault();
	}

	function toggleEdit(e) {
		setIsEditing(!isEditing);
		if (e) e.preventDefault();
	}

	const saveButtonClasses = ['textButton'];
	if (modifyingSaveState) saveButtonClasses.push('pulsing');

	let truncatedUsername = username;
	if (truncatedUsername.length > 18) {
		truncatedUsername = `${truncatedUsername.substring(0, 15)}...`;
	}

	return (
		<div className={`comment${isCollapsed ? ' collapsedComment' : ''}`}>
			<div
				className="commentHeader"
				role="button"
				tabIndex={0}
				onKeyPress={onKeyPress}
				onClick={() => setCollapsed(!isCollapsed)}
			>
				<FontAwesomeIcon
					icon={isCollapsed ? faPlusSquare : faMinusSquare}
					className="commentExpandCollapse"
					role="button"
					aria-label="toggle collapse"
					tabIndex={0}
					onKeyPress={onKeyPress}
					onClick={() => setCollapsed(!isCollapsed)}
				/>{' '}
				<Hideable hidden={deleted}>
					<span className="commentHeaderLightText">
						{timeDelta}
						<Hideable hidden={!edited}>* (edited {editedTimeDelta})</Hideable> by{' '}
					</span>
					<UserLink username={username} isAdmin={memberOf.find(group => group.name === 'Site Admins' && group.builtin)} />
				</Hideable>
				<Hideable hidden={!deleted}>
					<span className="commentHeaderLightText">{timeDelta}</span>
				</Hideable>{' '}
				/ <span className="commentValue">{formatCurrency(tips, true)}</span>{' '}
			</div>
			<Hideable hidden={!deleted}>
				<div className="commentBody deleted">[deleted]</div>
			</Hideable>
			<Hideable hidden={deleted}>
				{() => (
					<>
						<div className="commentBody">
							<Hideable hidden={isEditing}>
								{() => <SlateEditor displayOnly value={htmlSerializer.deserialize(bodyHTML)} />}
							</Hideable>
							<Hideable hidden={!isEditing}>
								{() => (
									<CommentEditorContainer
										onSubmit={() => setIsEditing(false)}
										actionCost={actionCost}
										postId={postId}
										communityId={communityId}
										commentId={_id}
										cancel={toggleEdit}
										className="commentEditContainer"
									/>
								)}
							</Hideable>
						</div>
						<div className="commentLinks">
							<Hideable hidden={username === sessionUsername}>
								<ArmableButton tiny disabled={!sessionUser} onConfirm={() => tipComment({ variables: { commentId: _id } })}>
									tip <span className="actionCost">{formatCurrency(actionCost)}</span>
								</ArmableButton>
							</Hideable>
							<Link to={`/s/${name}/comments/${postId}/${_id}`}>permalink</Link>
							<button
								type="button"
								className={saveButtonClasses.join(' ')}
								disabled={modifyingSaveState || !sessionUser}
								onClick={handleSaveComment}
							>
								{isSavedComment ? 'unsave' : 'save'}
							</button>
							<button type="button" disabled={!sessionUser} onClick={() => setReporting(!reporting)} className="textButton">
								{reporting ? 'cancel' : 'report'}
							</button>
							<Hideable hidden={username !== sessionUsername}>
								<button type="button" className="textButton" onClick={toggleEdit}>
									{isEditing ? 'cancel' : 'edit'}
								</button>
								<ArmableButton tiny onConfirm={() => deleteComment({ variables: { commentId: _id } })}>
									delete
								</ArmableButton>
							</Hideable>
							<button type="button" disabled={!sessionUser} className="textButton" onClick={toggleReply}>
								{isReplying ? 'cancel' : 'reply'}
							</button>
						</div>
						<Hideable hidden={!reporting}>
							<CommunityRuleViolationReportFormContainer
								onSaveOrCancel={() => setReporting(false)}
								communityId={communityId}
								objectId={_id}
								objectType="Comment"
							/>
						</Hideable>
					</>
				)}
			</Hideable>
			<div className="commentChildren">
				<Hideable hidden={!isReplying && children.length === 0}>
					<div
						role="button"
						className="collapseBorder"
						aria-label="toggle collapse"
						tabIndex={0}
						onKeyPress={onKeyPress}
						onClick={() => setCollapsed(true)}
					/>
				</Hideable>
				<div className="commentChildrenRight">
					<Hideable hidden={!isReplying}>
						{() => (
							<CommentEditorContainer
								className="commentReplyContainer"
								onSubmit={() => setIsReplying(false)}
								actionCost={actionCost}
								postId={postId}
								communityId={communityId}
								parentCommentId={_id}
								cancel={toggleReply}
							/>
						)}
					</Hideable>
					{children.length !== 0
						? children
								.sort((a, b) => {
									let diff;
									if (sortOrder === 'new') diff = new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
									else diff = b.tips - a.tips;
									if (diff === 0) return a._id.localeCompare(b._id) * -1;
									return diff;
								})
								.map(child => <CommentContainer sortOrder={sortOrder} ssr={ssr} commentId={child._id} key={child._id} />)
						: ''}
				</div>
			</div>
		</div>
	);
}

Comment.propTypes = {
	data: PropTypes.shape({
		comment: PropTypes.shape({
			_id: PropTypes.string,
			bodyHTML: PropTypes.string,
			deleted: PropTypes.bool,
			edited: PropTypes.bool,
			editDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
			creationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
			tips: PropTypes.number,
			user: PropTypes.shape({
				_id: PropTypes.string,
				username: PropTypes.string,
				memberOf: PropTypes.arrayOf(
					PropTypes.shape({
						_id: PropTypes.string,
						name: PropTypes.string,
						builtin: PropTypes.bool,
					})
				),
			}),
			sub: PropTypes.shape({
				actionCost: PropTypes.number,
				name: PropTypes.string,
				_id: PropTypes.string,
			}),
			children: PropTypes.arrayOf(
				PropTypes.shape({
					_id: PropTypes.string,
				})
			),
			post: PropTypes.shape({
				_id: PropTypes.string.isRequired,
			}),
		}),
		sessionUser: PropTypes.shape({
			username: PropTypes.string,
			savedItems: PropTypes.shape({
				comments: PropTypes.arrayOf(
					PropTypes.shape({
						_id: PropTypes.string.isRequired,
					})
				),
			}),
		}),
	}).isRequired,
	userActions: PropTypes.shape({
		tipComment: PropTypes.func,
		saveComment: PropTypes.func,
		unsaveComment: PropTypes.func,
		deleteComment: PropTypes.func,
	}).isRequired,
	modifyingSaveState: PropTypes.bool,
	ssr: PropTypes.bool,
	sortOrder: PropTypes.string,
};

Comment.defaultProps = {
	modifyingSaveState: false,
	ssr: true,
	sortOrder: 'top',
};
