import React, { SyntheticEvent, useState } from 'react';
import PropTypes from 'prop-types';
import htmlSerializer from '/client/app/components/common/inputs/slateEditor/htmlSerializer';
import SlateEditor from '/client/app/components/common/inputs/slateEditor/slateEditor';
import Hideable from '/client/app/components/common/hideable';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';

require('./communityRuleViolationReportForm.scss');

interface ICommunityRule {
	_id: string;
	title: string;
	detailedDescriptionHTML: string;
}

interface IProps {
	onSave: (ruleId: string, detailedReport: string, detailedReportHTML: string) => Promise<void> | void;
	onCancel: () => Promise<void> | void;
	communityRules: ICommunityRule[];
	siteWideRules: ICommunityRule[];
	communityId?: string;
}

export default function CommunityRuleViolationReportForm(props: IProps) {
	const { onSave, onCancel, communityId, communityRules, siteWideRules } = props;

	const [selectedRuleId, setSelectedRuleId] = useState<string>();
	const [detailedViolationDescription, setDetailedViolationDescription] = useState<string>('');
	const [detailedViolationDescriptionHTML, setDetailedViolationDescriptionHTML] = useState<string>('<p />');
	const [error, setError] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	async function save() {
		console.log(`Submitting with rule ID ${selectedRuleId} and detailed description ${detailedViolationDescription}`);
		if (selectedRuleId && detailedViolationDescription.trim().length > 0) {
			setError(undefined);
			setSubmitting(true);
			try {
				await onSave(selectedRuleId, detailedViolationDescription, detailedViolationDescriptionHTML);
				setSubmitted(true);
			} catch (err) {
				setError(err.message);
			}
			setSubmitting(false);
		}
	}

	function onDetailedViolationDescriptionChange(valueJS: any, value: Value) {
		const html = htmlSerializer.serialize(value);
		const plaintext = Plain.serialize(value);
		setDetailedViolationDescription(plaintext);
		setDetailedViolationDescriptionHTML(html);
	}

	function optionForRule(rule: ICommunityRule) {
		return (
			<option key={`ruleSelect.option.${rule._id}`} value={rule._id}>
				{rule.title}
			</option>
		);
	}

	function onSelectChange(e: SyntheticEvent<HTMLSelectElement>) {
		setSelectedRuleId(e.currentTarget.selectedOptions[0].value);
	}

	function selectForRules() {
		return (
			<div className="ruleSelectContainer">
				<select defaultValue="none" onChange={onSelectChange} className="ruleSelect" id="rules">
					<option value="none" disabled hidden>
						{' '}
						-- select violated rule --{' '}
					</option>
					<optgroup label="site rules">{siteWideRules.map(optionForRule)}</optgroup>
					<Hideable hidden={!communityId || communityRules.length === 0}>
						<optgroup label="community rules">{communityRules.map(optionForRule)}</optgroup>
					</Hideable>
				</select>
			</div>
		);
	}

	return (
		<div className="communityRuleViolationReportForm">
			<Hideable hidden={!submitted}>
				<div className="reportSuccess">report submitted</div>
			</Hideable>
			<Hideable hidden={submitted}>
				{selectForRules()}
				<SlateEditor
					editable
					placeholder="detailed description of rule violation"
					onChange={onDetailedViolationDescriptionChange}
				/>
				<Hideable hidden={!error}>
					<div className="error">{error}</div>
				</Hideable>
			</Hideable>
			<div className="communityRuleViolationReportFormButtons">
				<button type="button" className="standardButton" onClick={onCancel}>
					{submitted ? 'close' : 'cancel'}
				</button>
				<Hideable hidden={submitted}>
					<button
						type="button"
						disabled={submitting || !selectedRuleId || detailedViolationDescription.trim().length === 0}
						className="standardButton"
						onClick={save}
					>
						submit
					</button>
				</Hideable>
			</div>
		</div>
	);
}

CommunityRuleViolationReportForm.propTypes = {
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	communityId: PropTypes.string,
	communityRules: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			detailedDescriptionHTML: PropTypes.string.isRequired,
		})
	).isRequired,
	siteWideRules: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			detailedDescriptionHTML: PropTypes.string.isRequired,
		})
	).isRequired,
};

CommunityRuleViolationReportForm.defaultProps = {
	communityId: undefined,
};
