import React from 'react';
import { Link } from 'react-router-dom';

import './userLink.scss';

interface IProps {
	username: string;
	isAdmin: boolean;
}

export default function UserLink(props: IProps) {
	const { username, isAdmin } = props;
	const classes: string[] = [];
	if (isAdmin) classes.push('adminUserLink');
	const truncatedUsername = username.length > 26 ? `${username.substring(0, 23)}...` : username;
	return (
		<Link className={classes.join(' ')} to={`/u/${username}`}>
			{truncatedUsername}
		</Link>
	);
}
