import React, { ChangeEventHandler } from 'react';
import PropTypes from 'prop-types';

import './radioButtonGroup.scss';

interface IRadioButtonOption {
	id: string;
	value: string;
	label: any;
}

interface IProps {
	options: IRadioButtonOption[];
	horizontal: boolean;
	buttons: boolean;
	name: string;
	defaultOptionId?: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
}

/**
 * A flexible radio button class that displays a radio button in vertical or horizontal orientation,
 * as standard radio buttons or as buttons with the circles hidden.
 *
 * @param props
 * @constructor
 */
export default function RadioButtonGroup(props: IProps) {
	const { options, horizontal, buttons, name, defaultOptionId, onChange } = props;

	const classNames = [horizontal ? 'hToggle' : 'vToggle'];

	if (buttons) classNames.push('buttonToggle');

	return (
		<span className={classNames.join(' ')}>
			{options.map(option => (
				<React.Fragment key={`radioInputOptionFragment.${name}.${option.id}`}>
					<input
						key={`radioInputOption.${name}.${option.id}`}
						type="radio"
						id={option.id}
						name={name}
						value={option.value}
						defaultChecked={defaultOptionId === option.id}
						onChange={onChange}
						autoComplete="off"
					/>
					<label key={`radioInputLabel.${name}.${option.id}`} htmlFor={option.id}>
						{option.label}
					</label>
				</React.Fragment>
			))}
		</span>
	);
}

RadioButtonGroup.propTypes = {
	horizontal: PropTypes.bool,
	buttons: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.any.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	name: PropTypes.string.isRequired,
	defaultOptionId: PropTypes.string,
	onChange: PropTypes.func,
};

RadioButtonGroup.defaultProps = {
	horizontal: false,
	buttons: false,
	defaultOptionId: undefined,
	onChange: undefined,
};
