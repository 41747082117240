import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_COMMUNITY_RULE_VIOLATION_REPORT = gql`
	mutation createCommunityRuleViolationReport(
		$communityRuleId: String!
		$objectType: String!
		$objectId: String!
		$detailedViolationReport: String!
		$detailedViolationReportHTML: String!
	) {
		createCommunityRuleViolationReport(
			communityRuleId: $communityRuleId
			objectType: $objectType
			objectId: $objectId
			detailedViolationReport: $detailedViolationReport
			detailedViolationReportHTML: $detailedViolationReportHTML
		) {
			_id
			communityRule {
				_id
				community {
					_id
					moderators {
						_id
					}
					name
					creator {
						_id
						username
					}
					owner {
						_id
						username
					}
				}
				title
				detailedDescription
				detailedDescriptionHTML
			}
			detailedViolationReport
			detailedViolationReportHTML
			lastActionDate
			objectId
			objectType
			reportDate
			reporter {
				_id
				username
			}
			status
		}
	}
`;
