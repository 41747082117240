import React, { MouseEventHandler } from 'react';
import PropTypes from 'prop-types';

import Hideable from '/client/app/components/common/hideable';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './tag.scss';

export declare type TagType = 'site' | 'community' | 'post';

interface IProps {
	type: TagType;
	tagId: string;
	name: string;
	subTagId?: string;
	subTagName?: string;
	editable: boolean;
	onRemove: () => void;
	onSubTagChange: () => void;
	onClick: MouseEventHandler;
}

export default function Tag(props: IProps) {
	// TODO: Remove when used
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { type, tagId, name, subTagId, subTagName, editable, onRemove, onSubTagChange, onClick } = props;

	let url = `/tag/${tagId}`;
	if (subTagId) url += `/${subTagId}`;

	const classNames = ['tag'];
	if (type === 'site') classNames.push('siteTag');
	else if (type === 'community') classNames.push('communityTag');
	else classNames.push('postTag');

	if (editable) classNames.push('editable');

	return (
		<a href={onClick || editable ? undefined : url} onClick={onClick} className={classNames.join(' ')}>
			{name}
			<Hideable hidden={!subTagName}>
				<span className="subTag">{subTagName}</span>
			</Hideable>
			<Hideable hidden={!editable}>
				<button type="button" className="removeTagButton" onClick={onRemove}>
					<FontAwesomeIcon icon={faTimesCircle} />
				</button>
			</Hideable>
		</a>
	);
}

Tag.propTypes = {
	type: PropTypes.string.isRequired,
	tagId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	subTagId: PropTypes.string,
	subTagName: PropTypes.string,
	editable: PropTypes.bool,
	onRemove: PropTypes.func,
	onSubTagChange: PropTypes.func,
	onClick: PropTypes.func,
};

Tag.defaultProps = {
	subTagId: undefined,
	subTagName: undefined,
	editable: false,
	onRemove: undefined,
	onSubTagChange: undefined,
	onClick: undefined,
};
